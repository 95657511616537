/* Estilos para resultados de búsqueda */
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  z-index: 100;
  margin-top: 8px;
}

.search-result-item {
  display: flex;
  align-items: center;
  padding: 12px;
  cursor: pointer;
  border-bottom: 1px solid var(--border-color);
}

.search-result-item:last-child {
  border-bottom: none;
}

.search-result-item:hover {
  background-color: var(--bg-color);
}

.patient-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  margin-right: 12px;
}

.patient-info {
  flex: 1;
}

.patient-name {
  font-weight: 500;
}

.patient-details {
  font-size: 12px;
  color: var(--text-light);
}

.no-results {
  padding: 12px;
  text-align: center;
  color: var(--text-light);
}

/* Estilos para panel de notificaciones */
.notifications-panel {
  position: absolute;
  top: 100%;
  right: 0;
  width: 320px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 100;
  margin-top: 12px;
}

.notifications-header {
  padding: 12px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notifications-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.notifications-list {
  max-height: 300px;
  overflow-y: auto;
}

.notification-item {
  display: flex;
  padding: 12px;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
}

.notification-item:hover {
  background-color: var(--bg-color);
}

.notification-item.unread {
  background-color: rgba(0, 115, 234, 0.05);
}

.notification-icon {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: rgba(0, 115, 234, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  color: var(--primary-color);
}

.notification-content {
  flex: 1;
}

.notification-message {
  margin-bottom: 4px;
  font-size: 14px;
}

.notification-time {
  font-size: 12px;
  color: var(--text-light);
}

.notifications-footer {
  padding: 12px;
  text-align: center;
  border-top: 1px solid var(--border-color);
}

.btn-text {
  background: none;
  border: none;
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
}

.btn-text:hover {
  text-decoration: underline;
}

/* Estilos para el menú de usuario */
.user-menu {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  border-radius: 6px;
  transition: background-color 0.2s;
}

.user-menu:hover {
  background-color: var(--bg-color);
}

.user-menu .name {
  margin-left: 8px;
  font-weight: 500;
}

.user-menu-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 220px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 100;
  margin-top: 8px;
}

.user-info-header {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
  text-align: center;
}

.user-info-header .avatar {
  margin: 0 auto 8px;
  width: 48px;
  height: 48px;
}

.user-info-header .name {
  font-weight: 600;
  margin: 0;
}

.user-info-header .role {
  color: var(--text-light);
  font-size: 12px;
  margin-top: 4px;
}

.user-menu-items {
  padding: 8px 0;
}

.user-menu-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.user-menu-item:hover {
  background-color: var(--bg-color);
}

.user-menu-item svg {
  margin-right: 12px;
  color: var(--text-light);
}

.user-menu-item.danger {
  color: var(--danger-color);
}

.user-menu-item.danger svg {
  color: var(--danger-color);
}

/* Estilos para el modal de perfil */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  padding: 16px 24px;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-header h2 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.modal-close {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-light);
  font-size: 24px;
  padding: 0;
}

.modal-body {
  padding: 24px;
}

.modal-footer {
  padding: 16px 24px;
  border-top: 1px solid var(--border-color);
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

/* Estilos para formularios */
.form-group {
  margin-bottom: 20px;
}

.form-label {
  display: block;
  margin-bottom: 6px;
  font-weight: 500;
}

.form-control {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.form-control:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 115, 234, 0.2);
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

/* Selector de usuario */
.user-selector {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--border-color);
}

.user-selector-title {
  margin-bottom: 16px;
  font-weight: 600;
}

.user-selector-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 16px;
}

.user-selector-card {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
}

.user-selector-card:hover {
  border-color: var(--primary-color);
  transform: translateY(-2px);
}

.user-selector-card.active {
  border-color: var(--primary-color);
  background-color: rgba(0, 115, 234, 0.05);
}

.user-selector-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin: 0 auto 12px;
}

.user-selector-name {
  font-weight: 500;
  margin: 0 0 4px;
}

.user-selector-role {
  font-size: 12px;
  color: var(--text-light);
  margin: 0;
}
