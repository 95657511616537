:root {
  --primary-color: #0073ea;
  --primary-dark: #0060b9;
  --secondary-color: #00c875;
  --secondary-dark: #00a35c;
  --bg-color: #f6f7fb;
  --sidebar-color: #292f4c;
  --text-color: #323338;
  --text-light: #676879;
  --card-bg: #ffffff;
  --danger-color: #e44258;
  --warning-color: #fdab3d;
  --border-color: #e6e9ef;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--bg-color);
  color: var(--text-color);
}

/* Layout */
.app-container {
  display: flex;
  min-height: 100vh;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: var(--sidebar-color);
  color: white;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  z-index: 100;
}

.sidebar-header {
  padding: 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-header h1 {
  margin: 0;
  font-size: 22px;
  font-weight: 600;
}

.sidebar-header p {
  margin: 4px 0 0;
  font-size: 14px;
  opacity: 0.7;
}

.sidebar-menu {
  padding: 16px 8px;
  flex-grow: 1;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 4px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
  color: rgba(255, 255, 255, 0.8);
}

.menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.menu-item.active {
  background-color: var(--primary-color);
  color: white;
}

.menu-item svg {
  margin-right: 10px;
  width: 20px;
}

.user-profile {
  padding: 16px 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
}

.avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 12px;
}

.user-info {
  flex-grow: 1;
}

.user-info .name {
  font-weight: 500;
  margin: 0;
}

.user-info .role {
  font-size: 12px;
  opacity: 0.7;
  margin: 0;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Header */
.header {
  background-color: white;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.search-container {
  position: relative;
  width: 300px;
}

.search-container input {
  width: 100%;
  padding: 10px 16px 10px 40px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-color);
  font-size: 14px;
  transition: all 0.2s;
}

.search-container input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 115, 234, 0.2);
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-light);
}

.header-actions {
  display: flex;
  align-items: center;
}

.notification-bell {
  position: relative;
  cursor: pointer;
  color: var(--text-light);
  margin-right: 16px;
}

.notification-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--danger-color);
  color: white;
  font-size: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.user-menu .avatar {
  margin-right: 8px;
}

.user-menu .name {
  font-size: 14px;
  font-weight: 500;
}

/* Page Content */
.page-container {
  padding: 24px;
  overflow-y: auto;
}

.page-title {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 600;
}

.page-subtitle {
  margin-top: 0;
  margin-bottom: 24px;
  color: var(--text-light);
}

/* Dashboard Cards */
.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.stat-card {
  background-color: var(--card-bg);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
  display: flex;
  align-items: flex-start;
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.icon-container {
  width: 48px;
  height: 48px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.icon-blue {
  background-color: #0073ea;
  color: white;
}

.icon-green {
  background-color: #00c875;
  color: white;
}

.icon-orange {
  background-color: #fdab3d;
  color: white;
}

.icon-purple {
  background-color: #784bd1;
  color: white;
}

.stat-info h3 {
  margin: 0 0 4px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-light);
}

.stat-info .value {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.2;
}

/* Section Header */
.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 32px;
}

.section-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0;
  display: flex;
  align-items: center;
}

.section-title svg {
  margin-right: 8px;
  color: var(--primary-color);
}

/* Button Styles */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  font-size: 14px;
}

.btn svg {
  margin-right: 8px;
}

.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

.btn-primary:hover {
  background-color: var(--primary-dark);
}

.btn-outline {
  background-color: transparent;
  border: 1px solid var(--border-color);
  color: var(--text-color);
}

.btn-outline:hover {
  background-color: var(--bg-color);
}

/* Data Tables */
.table-container {
  background-color: var(--card-bg);
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-bottom: 32px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th {
  background-color: #f8f9fb;
  text-align: left;
  padding: 16px;
  font-weight: 600;
  color: var(--text-light);
  border-bottom: 1px solid var(--border-color);
}

.data-table td {
  padding: 16px;
  border-bottom: 1px solid var(--border-color);
}

.data-table tr:last-child td {
  border-bottom: none;
}

.data-table tr:hover {
  background-color: #f8f9fb;
}

/* Status Pills */
.status-pill {
  display: inline-flex;
  align-items: center;
  padding: 4px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
}

.status-confirmed {
  background-color: rgba(0, 200, 117, 0.1);
  color: #00c875;
}

.status-pending {
  background-color: rgba(253, 171, 61, 0.1);
  color: #fdab3d;
}

/* Action Buttons */
.action-btns {
  display: flex;
  gap: 8px;
}

.btn-view, .btn-edit {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  border: none;
}

.btn-view {
  background-color: rgba(0, 115, 234, 0.1);
  color: var(--primary-color);
}

.btn-view:hover {
  background-color: rgba(0, 115, 234, 0.2);
}

.btn-edit {
  background-color: rgba(103, 104, 121, 0.1);
  color: var(--text-light);
}

.btn-edit:hover {
  background-color: rgba(103, 104, 121, 0.2);
}

/* Empty State */
.empty-state {
  text-align: center;
  padding: 48px 24px;
}

.empty-state svg {
  color: var(--text-light);
  opacity: 0.5;
  font-size: 48px;
  margin-bottom: 16px;
}

.empty-state h3 {
  margin: 0 0 8px;
  font-size: 18px;
  font-weight: 600;
}

.empty-state p {
  margin: 0 0 16px;
  color: var(--text-light);
}

/* Responsive Design */
.mobile-toggle {
  display: none;
  background: none;
  border: none;
  color: var(--text-color);
  cursor: pointer;
  margin-right: 16px;
}

@media (max-width: 992px) {
  .sidebar {
    position: fixed;
    left: -280px;
    top: 0;
    bottom: 0;
    width: 280px;
  }

  .sidebar.open {
    left: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }

  .mobile-toggle {
    display: block;
  }

  .stats-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 768px) {
  .search-container {
    width: 180px;
  }

  .section-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .page-container {
    padding: 16px;
  }

  .stats-grid {
    grid-template-columns: 1fr;
  }
}

/* Overlay when mobile menu is open */
.menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 90;
}

.menu-overlay.active {
  display: block;
}
