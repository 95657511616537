/* Estilos para el formulario de registro de pacientes */
.registration-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.registration-content {
  display: flex;
  gap: 24px;
  margin-top: 24px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.registration-sidebar {
  width: 280px;
  border-right: 1px solid var(--border-color);
  padding: 16px 0;
}

.registration-nav-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  cursor: pointer;
  transition: background-color 0.2s;
  border-left: 3px solid transparent;
  position: relative;
}

.registration-nav-item svg {
  margin-right: 12px;
  color: var(--text-light);
}

.registration-nav-item:hover {
  background-color: var(--bg-color);
}

.registration-nav-item.active {
  background-color: var(--bg-color);
  border-left-color: var(--primary-color);
  font-weight: 500;
}

.registration-nav-item .icon-right {
  margin-left: auto;
  margin-right: 0;
  font-size: 12px;
}

.registration-form-container {
  flex: 1;
  padding: 24px;
  max-width: calc(100% - 280px);
  overflow: hidden;
}

.form-section {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section-title {
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: 600;
  color: var(--text-dark);
}

.form-divider {
  margin: 24px 0 16px;
  font-weight: 500;
  color: var(--text-dark);
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 8px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.form-check-input {
  margin-right: 8px;
}

.form-check-label {
  font-size: 14px;
}

/* Estilos para la sección de carga de archivos */
.upload-section {
  margin-bottom: 32px;
}

.upload-section h3 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500;
}

.upload-description {
  font-size: 14px;
  color: var(--text-light);
  margin-bottom: 16px;
}

.upload-container {
  margin-bottom: 24px;
}

.upload-dropzone {
  border: 2px dashed var(--border-color);
  border-radius: 8px;
  padding: 32px;
  text-align: center;
  cursor: pointer;
  transition: border-color 0.2s, background-color 0.2s;
  margin-bottom: 16px;
}

.upload-dropzone:hover {
  border-color: var(--primary-color);
  background-color: rgba(0, 115, 234, 0.05);
}

.upload-dropzone svg {
  color: var(--primary-color);
  margin-bottom: 8px;
}

.upload-dropzone p {
  margin: 8px 0 0;
  color: var(--text-light);
}

.file-preview-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 16px;
  margin-top: 16px;
}

.file-preview {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  overflow: hidden;
  background-color: white;
}

.image-preview {
  width: 100%;
  height: 120px;
  object-fit: cover;
  display: block;
}

.file-info {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  border-top: 1px solid var(--border-color);
}

.file-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

.btn-icon {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--danger-color);
  padding: 4px;
}

.file-list {
  margin-top: 16px;
}

.file-item {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
  margin-bottom: 8px;
  background-color: white;
}

.file-item svg {
  margin-right: 8px;
  color: var(--text-light);
}

/* Estilos para la sección de consentimientos */
.consent-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.consent-card {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  overflow: hidden;
}

.consent-card h3 {
  background-color: var(--bg-color);
  margin: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid var(--border-color);
}

.consent-content {
  padding: 16px;
}

.consent-content p {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 14px;
}

.signature-container {
  margin-top: 32px;
}

.signature-box {
  border: 1px solid var(--border-color);
  border-radius: 6px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-light);
  margin-top: 8px;
}

/* Estilos responsive */
@media (max-width: 992px) {
  .registration-content {
    flex-direction: column;
  }
  
  .registration-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border-color);
    padding: 0;
  }
  
  .registration-form-container {
    max-width: 100%;
  }
  
  .form-grid {
    grid-template-columns: 1fr;
  }
}
